import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Slider from './Slider'

export default function HomeSlider() {
    const data = useStaticQuery(graphql`
         query {
           backgrounds: 
            allGhostPost(
                sort: { order: DESC, fields: [published_at] }
                filter: {tags: {elemMatch: {slug: {eq: "slider"}}}},
            ) {
                edges {
                    node {
                ...GhostPostFields
                    }
                }
            }
         }
       `)
    const posts = data.backgrounds.edges
    return (
        <Slider posts={posts} />
    )
}
