import React, { useState } from 'react'

import BackgroundSlider from 'gatsby-image-background-slider'
import { Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper'

import { graphql, useStaticQuery } from 'gatsby'

// import Img from "gatsby-image"

import AwesomeSliderO from 'react-awesome-slider'
import withAutoplay from 'react-awesome-slider/dist/autoplay'
import Img from "gatsby-image"
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'
import 'swiper/swiper-bundle.min.css'
import "animate.css/animate.css"
import Typography from './Typography'

// const AwesomeSlider = withAutoplay(AwesomeSliderO);
export default function Slider({ posts, children }) {
    return <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
        spaceBetween={50}
        effect="fade"
        slidesPerView={1}
        navigation
        autoplay={{ delay: 10000 }}
    >
        {

            posts.filter(({ node }) => node.localImage).map(({ node }, i) => {
                let foregroundImage
                if (typeof window !== `undefined`) {
                    const parser = new window.DOMParser()
                    const htmlDoc = parser.parseFromString(node.html, `text/html`)
                    foregroundImage = htmlDoc.body.getElementsByTagName(`img`)[0]?.src
                }

                return <SwiperSlide >
                    {({ isActive }) => (
                        <div>
                            <Img className={isActive ? `filter blur` : `filter blur-md`} style={{ width: `100vw`, height: `100vh`, backgroundColor: `transparent`, position: `relative` }} imgStyle={{ objectFit: `cover` }} fixed={node.localImage.childImageSharp.fluid} />
                            <div style={{ position: `absolute`, bottom: `20vh`, width: `100%`, textAlign: `center` }}>
                                <div className="jumbotron">
                                    <div className="grid place-content-center ">
                                        {foregroundImage ?
                                            <img className={isActive ? `object-contain animate__animated animate__fadeInUp ` : `animate__animated animate__backOutUp`} style={{ width: `80vw`, height: `40vh`, backgroundColor: `transparent`, position: `relative` }} src={foregroundImage} /> : null
                                        }
                                    </div>
                                    <h1 className={isActive ? `animate__animated animate__bounceInUp text-3xl md:text-5xl lg:text-7xl font-noto dropShadow transition-all` : `animate__animated animate__backOutUp text-3xl md:text-5xl lg:text-8xl font-noto dropShadow transition-all`} style={{ color: `rgba(255,255,255,1)` }}>{node.title}</h1>
                                    <h3 className={isActive ? `animate__animated animate__bounceInLeft animate__delay-1s  text-5xl md:text-7xl lg:text-9xl gradient font-gemunu dropShadow text-white opacity-80 transition-all` : `animate__animated animate__backOutUp text-5xl md:text-7xl lg:text-9xl font-gemunu dropShadow text-white opacity-80 transition-all`}><Typography text={node.plaintext} /></h3>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
            })
        }
    </Swiper >
}
