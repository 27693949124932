import React, { useRef } from 'react'
import Img from "gatsby-image"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'
import { readingTime as readingTimeHelper } from '@tryghost/helpers'
import { getBuyUrl } from '../../utils/lib'
import { useInView } from 'react-intersection-observer';

const PostCard = ({ post, color }) => {
    const url = `/${post.slug}/`
    const readingTime = readingTimeHelper(post)

    const [ref, isVisible] = useInView({ threshold: 0 })
    const darazUrl = getBuyUrl(post.plaintext)
    const content = post.plaintext.split("\n")[0]
    return (
        <>
            <header className="post-card-header">
                <div ref={ref} className={`max-w-md mx-auto bg-white rounded-xl shadow-sm hover:shadow-md overflow-hidden md:max-w-2xl transition duration-500 ease-in-out opacity-${isVisible ? 100 : 0}`}>
                    <Link to={url} className="post-card">
                        {post.feature_image &&
                            post.localImage && <Img style={{ width: "100%", backgroundColor: 'transparent' }} imgStyle={{ objectFit: 'contain' }} className="post-card-image " fixed={post.localImage.childImageSharp.fluid} />
                        }
                        <div className="px-6">
                            <p className={`text-3xl text-center font-bold text-${color || 'green'}-600 font-sans`}>{post.title}</p>
                            {post.tags && <p className="mt-1 text-2xl text-center text-gray-500 font-noto">

                                {content}
                                {/* <Tags post={post} visibility="public" autolink={false} /> */}
                            </p>}
                        </div>
                    </Link>
                    <div className="p-6 text-center">

                        {darazUrl && <a href={darazUrl} target="_blank" target="_blank"  >
                            <button className={`transition ease-in-out px-4 py-3 text-md text-${color || 'green'}-500 font-sans rounded-xl  border-purple-200 hover:text-white bg-gradient-to-tr hover:from-${color || 'green'}-500 hover:to-${color || 'green'}-400 from-transparent hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 font-sans`}>Buy Now  <i className="fas fa-cart-plus animate-pulse "></i>
                            </button>
                        </a>}
                    </div>
                </div>
            </header>
        </>
    )
}

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        plaintext: PropTypes.string.isRequired,
        featured: PropTypes.bool,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

export default PostCard


export const PostCardList = ({ posts, color }) => <>{posts.map(({ node }) => (
    // The tag below includes the markup for each post - components/common/PostCard.js
    <PostCard key={node.id} post={node} color={color} />
))} </>