import React from 'react';

export default function Typography({ text }) {
  const parsedText = text.split(' ');

  const elements = parsedText.map(element => {
    if (element === 'daraz' || element === 'Daraz'){
      return <span className='font-noto font-semibold text-yellow-500 font-gemunu'>{element + " "} </span>
    }
    return <span>{element+ " "}</span>
  });
  return <div>{elements}</div>;
}
