import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/app.css';
import '../../styles/global.css';
import '../../styles/font.css';
import "@fortawesome/fontawesome-free/css/all.css"
import "@fortawesome/fontawesome-free/js/all.js"
import Slider from './Slider'
import HomeSlider from './HomeSlider'
import Footer from './Footer'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome }) => {
    const site = data.allGhostSettings.edges[0].node
    const pages = data.allGhostPage.nodes
    const [showMenu, setShowMenu] = React.useState(true);
    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }
    const navBarButtons = (
        <nav className={`site-nav flex-col sm:flex-row ${showMenu ? 'flex' : 'hidden sm:flex'}`}>
            <Link key={"---"} activeClassName="bg-green-500 text-white" className="site-nav-button no-underline m-2 hover:bg-green-700 hover:text-white rounded-lg transition-all" to={`/#home`}>Home</Link>
            <Link key={"---"} activeClassName="bg-green-500 text-white" className="site-nav-button no-underline  m-2 hover:bg-green-700 hover:text-white rounded-lg transition-all" to={`/#products`}>Products</Link>
            {pages.map(page => <div className="site-nav-right">
                <Link key={page.id} activeClassName="bg-green-500 text-white" className="site-nav-button  m-2 no-underline hover:bg-green-700 hover:text-white rounded-lg transition-all" to={`/${page.slug}`}>{page.title}</Link>
            </div>)
            }
        </nav>
    )
    const menuButton = (
        <div className='h-20 w-20 pt-2 flex  sm:hidden p-3 rounded-bl-xl'>
            <svg class={` h-14 w-14 text-green-500 transform transition-all ${showMenu && "rotate-90"}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" onClick={toggleMenu}>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
            </svg>
        </div>
    )
    return (
        <>
            <Helmet>
                <html lang={site.lang} />
                <style type="text/css">{`${site.codeinjection_styles}`}</style>
                <body className={bodyClass} />
            </Helmet>

            <div className="viewport h-screen overflow-hiddens overflow-y-scroll " style={{scrollBehavior:'smooth'}}>
                <div className="viewport-top">
                    <header className="site-head sticky top-0 pb-3 z-50 shadow-md bg-gradient-to-tl from-green-50 via-gray-100 to-gray-200" >
                        <div className="container">
                            <div className="site-mast">
                                <div className="site-mast-left ">
                                    <Link to="/" className="absolute top-0 bg-white rounded-b-full shadow-lg">
                                        {site.logo ?
                                            <img className="site-logo" src={site.logo} alt={site.title} />
                                            : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
                                        }
                                    </Link>

                                </div>
                                <div className="site-mast-right">
                                    {navBarButtons}
                                    {menuButton}
                                </div>
                            </div>
                            {isHome ?
                                <div className="site-banner">

                                </div> :
                                null}
                        </div>
                    </header>
                    <main className="site-main">
                        <section id="home">
                            {isHome && <HomeSlider />}
                        </section>
                        {children}
                    </main>

                </div>

                <div className="viewport-bottom">
                    <Footer />
                </div>
            </div>

        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }

                allGhostPage (filter: {tags:{elemMatch:{slug: {eq:"main"}}}}){
                    nodes{
                        title
                        slug
                        id
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
