import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

function FooterComp({ data }) {
    const categories = data.allGhostTag.edges
    const pages = data.allGhostPage.nodes

    return (
        <div>
            <footer className="site-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <h6>About</h6>
                            <p className="text-justify"><i>Walgama Ayurweda</i> A Sri Lankan company, making innovative herbal products and generic Ayurveda pharmaceuticals.</p>
                        </div>
                        <div className="col-xs-6 col-md-3">
                            <h6>Categories</h6>
                            <ul className="footer-links">
                                {categories.map(c =>
                                    <li key={c.node.slug}><a href={`/tag/${c.node.slug}`}>{c.node.name}</a></li>
                                )}
                            </ul>
                        </div>

                        <div className="col-xs-6 col-md-3">
                            <h6>Quick Links</h6>

                            <ul className="footer-links">
                                {
                                    pages.map(page =>
                                        <li key={page.slug}><a href={`/${page.slug}`}>{page.title}</a></li>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-sm-6 col-xs-12">
                            <p className="copyright-text">Copyright &copy; 2021 All Rights Reserved by <a href="#">Walgama Ayurweda</a></p>
                        </div>

                        <div className="col-md-4 col-sm-6 col-xs-12">
                            <ul className="social-icons">
                                <li><a className="facebook" href="https://www.facebook.com/Walgamaayurveda"><i className="fab fa-facebook"></i></a></li>
                                <li><a className="email" href="mailto:walgamaproducts@gmail.com"><i className="fab fa-envelope"></i></a></li>
                                <li><a className="whatsapp" href="https://wa.me/+94771718875"><i className="fab fa-whatsapp"></i></a></li>
                                <li><a className="instagram" href="https://www.instagram.com/walgamaayurveda/?hl=en"><i className="fab fa-instagram"></i></a></li>
                                <li><a className="linkedin" href="https://www.linkedin.com/search/results/all/?keywords=Walgama%20Ayurvedic%20Products%20(Pvt)%20Ltd"><i className="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div >
    )
}

const Footer = props => (
    <StaticQuery
        query={graphql`
            query FooterData {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                allGhostPage (filter: {tags:{elemMatch:{slug: {eq:"main"}}}}){
                    nodes{
                        title
                        slug
                        id
                    }
                }


                allGhostTag(filter:{slug:{regex:"/products-/"}}){
                edges {
                node {
                    ...GhostTagFields
                }
                }
                }

            }
        `}
        render={data => <FooterComp data={data} {...props} />}
    />
)

export default Footer